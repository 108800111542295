import { avalancheFuji, mantle } from '@reown/appkit/networks'
import { createAppKit } from '@reown/appkit/react'
import { WagmiAdapter } from '@reown/appkit-adapter-wagmi'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ChainId } from '@traderjoe-xyz/sdk-core'
import { isTestnet } from 'constants/chains'
import React from 'react'
import { e2eTestsConfig, isRunningE2ETests } from 'utils/e2e'
import { http, WagmiProvider } from 'wagmi'

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: false } }
})

const walletConnectProjectId =
  process.env.REACT_APP_WALLET_CONNECT_PROJECT_ID ?? ''

const metadata = {
  description: 'Explore DeFi on Mantle Network with Merchant Moe',
  icons: [],
  name: 'Merchant Moe',
  url: 'https://merchantmoe.com/'
}

const wagmiAdapter = new WagmiAdapter({
  batch: { multicall: true },
  connectors: isRunningE2ETests ? e2eTestsConfig.connectors : [],
  networks: isTestnet ? [avalancheFuji] : [mantle],
  projectId: walletConnectProjectId,
  transports: isRunningE2ETests
    ? e2eTestsConfig.transports
    : {
        [ChainId.MANTLE]: http('https://rpc.mantle.xyz'),
        [ChainId.FUJI]: http('https://api.avax-test.network')
      }
})

createAppKit({
  adapters: [wagmiAdapter],
  features: {
    analytics: true,
    email: false,
    legalCheckbox: true,
    socials: false
  },
  metadata,
  networks: isTestnet ? [avalancheFuji] : [mantle],
  privacyPolicyUrl: 'https://merchantmoe.com/privacy',
  projectId: walletConnectProjectId,
  termsConditionsUrl: 'https://merchantmoe.com/tos'
})

export function AppKitProvider({ children }: { children: React.ReactNode }) {
  return (
    <WagmiProvider config={wagmiAdapter.wagmiConfig}>
      <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
    </WagmiProvider>
  )
}
