import { Heading, SimpleGrid, Skeleton, Text, VStack } from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import React from 'react'
import { formattedNum } from 'utils/format'

interface JoeStakingAnalyticsPanelProps {
  isLoadingStakingData: boolean
  totalJoeStaked?: string
}

const JoeStakingAnalyticsPanel = ({
  isLoadingStakingData,
  totalJoeStaked
}: JoeStakingAnalyticsPanelProps) => {
  return (
    <VStack
      bg="bgCard"
      borderRadius={{ base: '10px', md: '20px' }}
      p={{ base: 4, md: 8 }}
      boxShadow="element"
      spacing={{ base: 4, md: 8 }}
      align="flex-start"
      w="full"
    >
      <Heading size="md">Analytics</Heading>

      <SimpleGrid columns={2} w="full" rowGap={8}>
        <VStack spacing={0} align="flex-start">
          <Text textColor="textSecondary" fontSize="sm">
            <Trans>Total JOE Staked</Trans>
          </Text>
          <Skeleton isLoaded={!isLoadingStakingData}>
            <Heading size="md">
              {formattedNum(totalJoeStaked || '0')} JOE
            </Heading>
          </Skeleton>
        </VStack>

        <VStack spacing={0} align="flex-start">
          <Text textColor="textSecondary" fontSize="sm">
            <Trans>MOE emission</Trans>
          </Text>
          <Skeleton isLoaded={!isLoadingStakingData}>
            <Heading size="md">0 MOE / day</Heading>
          </Skeleton>
        </VStack>

        <VStack spacing={0} align="flex-start">
          <Text textColor="textSecondary" fontSize="sm">
            <Trans>APR</Trans>
          </Text>
          <Skeleton isLoaded={!isLoadingStakingData}>
            <Heading size="md">0%</Heading>
          </Skeleton>
        </VStack>

        <VStack spacing={0} align="flex-start">
          <Text textColor="textSecondary" fontSize="sm">
            <Trans>Rewards vested</Trans>
          </Text>
          <Skeleton isLoaded={!isLoadingStakingData}>
            <Heading size="md">100%</Heading>
          </Skeleton>
        </VStack>
      </SimpleGrid>
    </VStack>
  )
}

export default JoeStakingAnalyticsPanel
