import { ArrowForwardIcon } from '@chakra-ui/icons'
import {
  Button,
  Center,
  Flex,
  Heading,
  SimpleGrid,
  Text,
  VStack
} from '@chakra-ui/react'
import { Trans } from '@lingui/macro'
import PageContainer from 'components/PageContainer'
import { useGetJoeStakingData } from 'hooks/airdrop/useJoeStaking'
import debounce from 'lodash.debounce'
import React from 'react'
import { BridgeIcon } from 'theme/icons'

import ClaimMoePanel from './ClaimMoePanel'
import JoeStakingAnalyticsPanel from './JoeStakingAnalyticsPanel'
import StakedJoeAmountPanel from './StakedJoeAmountPanel'
import StakeJoePanels from './StakeJoePanels'

const Airdrop = () => {
  const {
    data: {
      joeTotalDepositedAmount,
      userClaimableMoeAmount,
      userJoeDepositedAmount
    } = {},
    isLoading: isLoadingStakingData,
    refetch: refetchJoeStakingData
  } = useGetJoeStakingData()
  const debouncedRefetchJoeStakingData = debounce(
    () => refetchJoeStakingData(),
    2000
  )

  return (
    <PageContainer mb="100px">
      <Flex
        align={{ base: 'flex-start', md: 'center' }}
        justify="space-between"
        direction={{ base: 'column', md: 'row' }}
        gap={4}
      >
        <VStack align="flex-start">
          <Heading>
            <Trans>Joe Staking</Trans>
          </Heading>
          <VStack spacing={0} align="flex-start">
            <Text fontSize="sm" textColor="textSecondary">
              <Trans>
                The 12-month staking period for JOE holders to receive a
                distribution of MOE tokens has ended.
              </Trans>
            </Text>
            <Text fontSize="sm" textColor="textSecondary">
              <Trans>
                JOE can be unstaked and any accrued MOE can be claimed at
                anytime.
              </Trans>
            </Text>
            <Text fontSize="sm" textColor="textSecondary">
              <Trans>
                The native LayerZero bridge can be used to bridge JOE to
                Avalanche and Arbitrum.
              </Trans>
            </Text>
          </VStack>
        </VStack>

        <Button
          p={4}
          size="2xl"
          bg="graphPurpleDark"
          textColor="white"
          borderRadius="20px"
          leftIcon={
            <Center p={3} bg="graphPurpleLight" borderRadius="10px" mr="10px">
              <BridgeIcon />
            </Center>
          }
          rightIcon={
            <Center p={3} bg="deepPurple" borderRadius="10px" ml="40px">
              <ArrowForwardIcon />
            </Center>
          }
          boxShadow="0px 15px 50px 0px color(display-p3 0.6784 0.5216 0.8824 / 0.50)"
          _hover={{ opacity: 0.8 }}
          _active={{ opacity: 0.6 }}
          onClick={() => window.open('https://bridge.lfj.gg', '_blank')}
        >
          Bridge JOE Tokens
        </Button>
      </Flex>

      <SimpleGrid
        columns={{ base: 1, md: 2 }}
        mt="50px"
        spacing={{ base: '50px', xl: '125px' }}
        alignItems="flex-start"
      >
        <VStack spacing={10}>
          <StakeJoePanels
            joeDepositedAmount={userJoeDepositedAmount?.formatted}
            onUnstakeSuccess={debouncedRefetchJoeStakingData}
          />
          <StakedJoeAmountPanel
            joeDepositedAmount={userJoeDepositedAmount?.formatted}
          />
          <ClaimMoePanel
            claimableMoeAmount={userClaimableMoeAmount?.formatted || '0'}
            onClaimSuccess={debouncedRefetchJoeStakingData}
          />
        </VStack>
        <JoeStakingAnalyticsPanel
          isLoadingStakingData={isLoadingStakingData}
          totalJoeStaked={joeTotalDepositedAmount?.formatted}
        />
      </SimpleGrid>
    </PageContainer>
  )
}

export default Airdrop
