import { chain } from 'constants/chains'
import { getAddress, Hex, http, zeroAddress } from 'viem'
import { generatePrivateKey, privateKeyToAddress } from 'viem/accounts'
import { CreateConnectorFn } from 'wagmi'

import { mock } from './mock'

export const isRunningE2ETests = Boolean(window.Cypress)

const forkRpcUrl = localStorage.getItem('forkRpcUrl')

let mockConnector: CreateConnectorFn | undefined
let testAccount: Hex = zeroAddress
if (isRunningE2ETests) {
  const testWalletAddress = localStorage.getItem('testWalletAddress')

  testAccount = testWalletAddress
    ? getAddress(testWalletAddress)
    : privateKeyToAddress(generatePrivateKey())

  mockConnector = mock({
    accounts: [testAccount]
  })
}

export const e2eTestsConfig = {
  chain,
  connectors: mockConnector ? [mockConnector] : [],
  testAccount,
  transports: {
    [chain.id]: forkRpcUrl ? http(forkRpcUrl, { timeout: 90_000 }) : http()
  }
}
